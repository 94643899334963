import {
  GrowthBookFactory,
  createUseFeatureValue,
  createGetFeatureValue,
} from '@leyan/growthbook-react';

import { ProjectAttributes, ProjectFeatures } from './types';

// 创建 GrowthBookFactory 用来批量操作 Features, 新添加的 Feature, 在下面新增
export const growthBookFactory = new GrowthBookFactory<ProjectFeatures, ProjectAttributes>(
  {
    apiHost: 'https://pre-growthbook-proxy.leyanbot.com',
    clientKey: 'kensa:VZ7RKqI9zpRCn8kPGRiX',
    environment: process.env.NODE_ENV === 'production' ? 'production' : 'development',
    appName: process.env.REACT_APP_NAME || '',
    appVersion: process.env.REACT_APP_VERSION || '',
    // growthbook的所有features 2小时过期
    ttl: 2 * 60 * 60 * 1000,
  },
  // feature 的含义见 types
  {
    downgrade: {
      defaultValue: {
        activityImportSpu: false,
        dashboard: false,
        syncCustomerScenario: false,
        kensa: false,
      },
      ttl: (15 * 60 - 1) * 1000,
      refreshInterval: 15 * 60 * 1000,
    },
  },
);

/**
 * 在 React 中使用方式
 */
export const useFeatureValue = createUseFeatureValue<ProjectFeatures, ProjectAttributes>(
  growthBookFactory,
);

/**
 * 在 JS 中使用方式
 */
export const getFeatureValue = createGetFeatureValue<ProjectFeatures, ProjectAttributes>(
  growthBookFactory,
);
