import { AxiosRequestConfig } from 'axios';
import Restful from 'utils/Restful';
// eslint-disable-next-line
import { inspectorClient } from './clients';

interface APIResult<T> {
  result: {
    code: number;
    msg: string;
  };
  data: T;
}
export enum FunctionType {
  Invalid,
  Readonly,
  ReadWrite,
}
export interface UserFunctionItem {
  functionId: number;
  parentId: number;
  functionCode: string;
  functionName: string;
  functionType: FunctionType;
  functionDisplayName: string;
}
export interface FeedbackRecord {
  userName: string;
  mobile: string;
  content: string;
  functionName: string;
}
export interface CurrentInfo {
  userId: number;
  type: number;
  avatar: string;
  username: string;
  storeId: string;
  sellerNick: string;
  roleList: {
    id: number;
    roleName: string;
    roleCode: string;
  }[];
}
class BaseApi extends Restful {
  constructor(options: any) {
    super({
      entry: '/base',
      ...options,
    });
  }

  getFunctionList() {
    return this.custom('function')
      .custom('search')
      .list({ params: { pageSize: 10000 } });
  }

  getUserFunction(): Promise<UserFunctionItem[]> {
    return this.one('user').custom('userFunction').get();
  }

  feedback(data: FeedbackRecord, config?: AxiosRequestConfig): Promise<APIResult<null>> {
    return this.custom('feedback').post(data, config);
  }

  getCurrentInfo(): Promise<CurrentInfo> {
    return this.one('user').custom('currentInfo').get();
  }

  getFunctionFlagByPackageCode(code: string): Promise<APIResult<boolean>> {
    return this.one('function').custom('getFunctionFlagByPackageCode').post({ packageCode: code });
  }
}

export default new BaseApi({
  client: inspectorClient,
});
