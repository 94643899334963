import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import refine from 'utils/refine';
import isExternalPath from 'utils/isExternalPath';

interface IAppLink {
  to?: string | object | Function;
  replace?: boolean;
  children: React.ReactNode;
  [x: string]: any;
}
const AppLink: FunctionComponent<IAppLink> = (props) => {
  const { to: toProp, replace, children, ...otherProps } = props;

  const to = refine(toProp, props);
  const isAction = to === undefined;
  const isExternal = typeof to === 'string' && isExternalPath(to);

  if (isAction || isExternal) {
    return (
      <a {...otherProps} rel="noopener noreferrer" href={to}>
        {children}
      </a>
    );
  }

  return (
    <Link {...otherProps} replace={replace} to={to}>
      {children}
    </Link>
  );
};

export default AppLink;
