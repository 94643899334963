import { useCallback, useMemo, useRef, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { useLocation, useHistory } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { parseUrl, stringify, ParsedUrl } from 'query-string';

interface LocationLike {
  pathname: string;
  search: string;
  hash: string;
  state?: any;
}

const RoutingContainer = createContainer(() => {
  const history = useHistory();
  const location = useLocation();
  const locationRef = useRef(location);
  const historyRef = useRef(history);
  const lastLocation = usePrevious(location);
  const lastLocationRef = useRef(lastLocation);
  const anchorsRef = useRef<{ [x: string]: LocationLike }>({});
  const queryRef = useRef<ParsedUrl['query']>({});
  const query = useMemo(() => {
    const { search } = location;
    queryRef.current = parseUrl(search).query || ({} as ParsedUrl['query']);
    return queryRef.current;
  }, [location]);

  const { query: lastQuery } = useMemo(() => {
    if (!lastLocation) {
      return { query: {} };
    }
    const { search } = lastLocation;
    return parseUrl(search);
  }, [lastLocation]);

  const gotoAnchor = useCallback(
    (anchorKey: string | undefined = undefined, fallbackLocation: string = '/') => {
      const { current: anchors } = anchorsRef;
      const { current: history } = historyRef;
      const { current: lastLocation } = lastLocationRef;
      if (anchorKey && anchors[anchorKey]) {
        const { pathname, search } = anchors[anchorKey];
        history.replace(`${pathname}${search}`);
      } else if (lastLocation) {
        history.replace(lastLocation);
      } else {
        history.push(fallbackLocation);
      }
    },
    [],
  );
  const appendQuery = useCallback(
    (incomeQuery: { [x: string]: any }, forceReplace: boolean = false) => {
      const { current: history } = historyRef;
      const { current: location } = locationRef;
      const { current: query } = queryRef;
      const nextQuery = forceReplace ? incomeQuery : { ...query, ...incomeQuery };
      history.replace({
        ...location,
        search: stringify(nextQuery),
      });
    },
    [],
  );
  const setAnchor = useCallback((key?: string, targetLocation?: LocationLike) => {
    const { current: location } = locationRef;
    const targekey = key || location.pathname;
    anchorsRef.current = {
      ...anchorsRef.current,
      [targekey]: targetLocation || location,
    };
  }, []);
  const getAnchor = useCallback((key: string) => {
    return anchorsRef.current[key];
  }, []);
  useEffect(() => {
    locationRef.current = { ...location };
  }, [location]);
  useEffect(() => {
    historyRef.current = history;
  }, [history]);
  useEffect(() => {
    lastLocationRef.current = lastLocation;
  }, [lastLocation]);
  return {
    gotoAnchor,
    appendQuery,
    query,
    lastQuery,
    setAnchor,
    getAnchor,
    lastLocation,
  };
});
export const useRouting = () => {
  const routing = RoutingContainer.useContainer();
  return routing;
};
export const useRoutingQuery = (key: string): [any, (nextState: any) => any] => {
  const {
    query: { [key]: value },
    appendQuery,
  } = RoutingContainer.useContainer();
  const setState = useCallback(
    (nextState: any) => {
      appendQuery({ [key]: nextState });
    },
    [key, appendQuery],
  );
  return [value, setState];
};

export default RoutingContainer;
