import { useRequest, useUpdateEffect } from '@umijs/hooks';
import { message } from 'antd';
import baseApi, { CurrentInfo, FunctionType, UserFunctionItem } from 'apis/baseApi';
import { authorize as authorizeClients } from 'apis/clients';
import { growthBookFactory } from 'apis/growthbook';
import jwt from 'jsonwebtoken';
import { setupUser } from 'lito.config';
import { useCallback, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import a from 'utils/a';
import { Itoken, TPayload } from './helpers';

// FIXME: 当前未发现比较好的办法从组件外获取 unstated-next 的状态，临时通过局部变量缓存信息
let INTERNAL_TOKEN: string | null = null;

export function getToken() {
  return INTERNAL_TOKEN;
}

type AccessabilityMap = {
  [x: string]: UserFunctionItem['functionType'];
};
type TAuthorization = {
  token?: string;
  storeId?: string;
  loading?: boolean;
  payload?: TPayload | Itoken | null;
  isAuthed: boolean;
  userFunctions?: UserFunctionItem[];
  accessabilityMap?: AccessabilityMap;
};

const parseAuthorization = (
  token: string | undefined,
  userFunctions: UserFunctionItem[],
  loading: boolean,
  storeId: string | undefined,
): TAuthorization => {
  if (loading) {
    return { isAuthed: true, loading };
  }
  if (token) {
    const payload = jwt.decode(token) as Itoken | TPayload;
    const accessabilityMap = userFunctions.reduce((map, { functionType, functionCode }) => {
      if (functionType === FunctionType.ReadWrite || functionType === FunctionType.Readonly) {
        return { ...map, [functionCode]: functionType };
      }
      return map;
    }, {} as AccessabilityMap);
    return {
      isAuthed: true,
      token,
      storeId,
      payload,
      userFunctions,
      accessabilityMap,
      loading,
    };
  }
  return { isAuthed: false };
};
const AuthContainer = createContainer(() => {
  const [token, setToken] = useState<string | undefined>();
  const [storeId, setStoreId] = useState<string | undefined>();
  const [currentInfo, setCurrentInfo] = useState<CurrentInfo>({} as CurrentInfo);
  const [loading, setLoading] = useState(true);
  const [userFunctions, setUserFunctions] = useState<UserFunctionItem[]>([]);
  const [applyAddTrainCase, setAddTrainCase] = useState<boolean>(false);

  const authorize = useCallback(
    (newToken: string, newStoreId: string) => {
      setToken(newToken);
      setStoreId(newStoreId);
    },
    [setToken, setStoreId],
  );
  const unAuthorize = () => {
    setToken(undefined);
    setStoreId(undefined);
    setLoading(false);
    growthBookFactory.stop();
  };
  const { run: getUserFunctions } = useRequest(
    async () => {
      setLoading(true);

      if (token && storeId) {
        setupUser(token, storeId);
      }

      const userfunctionPromise = baseApi.getUserFunction();
      const currentInfoPromise = baseApi.getCurrentInfo();
      const trainPromise = baseApi.getFunctionFlagByPackageCode('TRAINING-INSPECTOR');
      return Promise.all([userfunctionPromise, currentInfoPromise, trainPromise])
        .then(async ([userFunctions, currentInfo, trainData]) => {
          setCurrentInfo(currentInfo || {});
          setUserFunctions(userFunctions || []);
          setAddTrainCase(!!trainData.data);
          a.config({
            sid: currentInfo.storeId,
            roles: currentInfo.roleList.map((role) => role.roleName).join(';'),
          });
          const { storeId, sellerNick: nick } = currentInfo;
          growthBookFactory.setAttributes({
            sid: storeId,
            nick,
          });

          await growthBookFactory.start();
        })
        .catch((err) => {
          if (err.status !== 403 && err.status !== 401) {
            message.error(err.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    { manual: true, refreshDeps: [token] },
  );
  useUpdateEffect(() => {
    INTERNAL_TOKEN = token ?? null;
    authorizeClients(token || '', storeId || '');
    if (!token || !storeId) {
      return;
    }

    getUserFunctions();
  }, [token]);
  const authorization: TAuthorization = useMemo(() => {
    return parseAuthorization(token, userFunctions, loading, storeId);
  }, [token, userFunctions, loading, storeId]);

  return {
    authorization,
    currentInfo,
    applyAddTrainCase,
    authorize,
    unAuthorize,
  };
});
export const useAuthorization = () => {
  const auth = AuthContainer.useContainer();
  return auth;
};
export default AuthContainer;
