import React from 'react';
import loadable from '@loadable/component';
import { Icon } from 'antd';
import Kicon from 'kicon.config';
import Loading from 'components/Loading';
import { RouteData } from 'components/MenuUseRouter';
import { ReactComponent as DashboardIcon } from 'assets/menu/dashboard.svg';
import { ReactComponent as SessionIcon } from 'assets/menu/session.svg';
import { ReactComponent as ReviewIcon } from 'assets/menu/manualreview.svg';
import { ReactComponent as ConsoleIcon } from 'assets/menu/control.svg';
import { ReactComponent as SettingIcon } from 'assets/menu/setting.svg';
import { ReactComponent as EvaluationIcon } from 'assets/menu/evaluation.svg';

const options = {
  fallback: <Loading />,
};

const Forbidden = loadable(() => import('views/Forbidden'), options);
const BasicManagement = loadable(() => import('views/BasicManagement'), options);
const Rules = loadable(() => import('views/Console/Scenes/Rules'), options);
const Supervision = loadable(() => import('views/Console/Scenes/Supervision'), options);
const ManualReview = loadable(() => import('views/ManualReview'), options);
const SessionList = loadable(() => import('views/Session/Scenes/SessionList'), options);
const SessionDetail = loadable(() => import('views/Session/Scenes/Detail'), options);
const Realtime = loadable(() => import('views/Dashboard/scenes/Realtime'), options);
const Trainings = loadable(() => import('views/Trainings'), options);
const TrainList = loadable(() => import('views/Train/Scenes/TrainList'), options);
const TrainCases = loadable(() => import('views/Train/Scenes/TrainCases'), options);
const TrainSession = loadable(() => import('views/Train/Scenes/TrainSession'), options);
const GoodsIssue = loadable(() => import('views/Dashboard/scenes/Emotion/GoodsIssue'), options);
const LogisticsIssue = loadable(
  () => import('views/Dashboard/scenes/Emotion/LogisticsIssue'),
  options,
);
const AfterSale = loadable(() => import('views/Dashboard/scenes/Emotion/AfterSale'), options);
const Dashboard2 = loadable(() => import('views/Dashboard2'), options);
const Emotion = loadable(() => import('views/Dashboard/scenes/Emotion'), options);
const Dashboard = loadable(() => import('views/Dashboard/scenes/Overview'), options);
const CustomerService = loadable(() => import('views/Dashboard/scenes/CustomerService'), options);
const Evaluate = loadable(() => import('views/Evaluation/Scenes/Evaluate'), options);

const DashboardRoutes: RouteData[] = [
  {
    functionCode: 'summary_report_view',
    path: '/dashboard/overview',
    key: 'dashboard/overview',
    label: '汇总报告',
    component: Dashboard,
  },
  {
    functionCode: 'quality_qos_report_view',
    path: '/dashboard/customerservice',
    key: 'dashboard/customerservice',
    label: '客服服务质量追踪',
    component: CustomerService,
  },
  {
    functionCode: 'intime_report_view',
    path: '/dashboard/realtime',
    key: 'dashboard/realtime',
    label: '实时报告',
    component: Realtime,
  },
  {
    functionCode: 'emotional_view',
    path: '/dashboard/emotion',
    key: 'dashboard/emotion',
    label: '买家情绪罗盘',
    component: Emotion,
  },
];
const ConsoleRoutes: RouteData[] = [
  {
    functionCode: 'deduction_config_view',
    path: '/console/rules',
    key: 'console/rules',
    label: '规则设置',
    component: Rules,
  },
  {
    functionCode: 'supervision_config_view',
    path: '/console/supervision',
    key: 'console/supervision',
    label: '督导设置',
    component: Supervision,
  },
];
const TrainRoutes: RouteData[] = [
  {
    functionCode: 'training_view',
    path: '/train/list',
    key: 'train/list',
    label: '自我提升',
    component: TrainList,
  },
  {
    hidden: true,
    functionCode: 'training_view',
    path: '/train/session/:num?/:count?',
    key: 'train/session',
    label: '仿真培训',
    frameless: true,
    component: TrainSession,
  },
  {
    functionCode: 'train_case_view',
    path: '/train/cases',
    key: 'train/cases',
    label: '培训案例管理',
    component: TrainCases,
  },
];

export const kensaRoutes: RouteData[] = [
  {
    icon: <Icon component={DashboardIcon} />,
    label: '数据监控',
    key: 'dashboard',
    items: DashboardRoutes,
  },
  {
    functionCode: 'session_list_view',
    icon: <Icon component={SessionIcon} />,
    label: '会话列表',
    key: 'session/list',
    path: '/session/list',
    component: SessionList,
  },
  {
    functionCode: 'session_detail_view',
    hidden: true,
    key: '/session/detail',
    path: '/session/detail/:id?',
    label: '会话详情',
    frameless: true,
    component: SessionDetail,
  },
  {
    functionCode: 'reinspector_view',
    icon: <Icon component={ReviewIcon} />,
    path: '/manualreview',
    key: 'manualreview',
    label: '人工复检',
    component: ManualReview,
  },
  {
    icon: <Icon component={ConsoleIcon} />,
    label: '控制台',
    key: 'console',
    items: ConsoleRoutes,
  },
  {
    functionCode: 'traderate_view',
    icon: <Icon component={EvaluationIcon} />,
    label: '中差评分析',
    key: 'evaluation',
    path: '/evaluation',
    component: Evaluate,
  },
  {
    icon: <Icon component={ConsoleIcon} />,
    label: '客服培训',
    key: 'train',
    items: TrainRoutes,
  },
  {
    functionCode: 'authorization_config_view',
    icon: <Icon component={SettingIcon} />,
    label: '基础管理',
    key: 'basicManagement',
    path: '/basicManagement',
    component: BasicManagement,
  },
  {
    path: '/forbidden/:code?',
    hidden: true,
    auther: true,
    key: 'forbidden',
    label: 'forbbiden',
    frameless: true,
    component: Forbidden,
  },
];

export const cemRoutes: RouteData[] = [
  {
    key: 'consults',
    icon: <Kicon type="kicon-chat-fill" />,
    label: '咨询',
    items: [
      {
        key: 'realtime',
        functionCode: 'intime_report_view',
        path: '/cem/consults/realtime',
        label: '实时报告',
        component: Realtime,
      },
      {
        key: 'dashboard',
        functionCode: 'summary_report_view',
        path: '/cem/consults/dashboard',
        label: '汇总报表',
        component: Dashboard2,
      },
      {
        key: 'session',
        functionCode: 'session_detail_view',
        path: '/cem/consults/sessions/:id',
        label: '会话详情',
        component: SessionDetail,
        hidden: true,
        frameless: true,
      },
      {
        key: 'sessions',
        functionCode: 'session_list_view',
        path: '/cem/consults/sessions',
        label: '会话列表',
        component: SessionList,
        exact: true,
      },
      {
        key: 'reviews',
        functionCode: 'reinspector_view',
        path: '/cem/consults/reviews',
        label: '人工复检',
        component: ManualReview,
      },
      {
        key: 'training',
        functionCode: 'training_view',
        path: '/cem/consults/trainings/:num/:count',
        label: '仿真训练',
        component: TrainSession,
        hidden: true,
        frameless: true,
      },
      {
        key: 'trainings',
        functionCode: ['training_view', 'train_case_view'],
        path: '/cem/consults/trainings',
        label: '客服培训',
        component: Trainings,
      },
    ],
  },
  {
    key: 'items',
    functionCode: 'emotional_view',
    path: '/cem/items',
    icon: <Kicon type="kicon-shopping-bag-fill" />,
    label: '商品',
    component: GoodsIssue,
  },
  {
    key: 'logistics',
    functionCode: 'emotional_view',
    path: '/cem/logistics',
    icon: <Kicon type="kicon-truck-fill" />,
    label: '物流',
    component: LogisticsIssue,
  },
  {
    key: 'after-sales',
    functionCode: 'emotional_view',
    path: '/cem/after-sales',
    icon: <Kicon type="kicon-customer-service-fill" />,
    label: '售后',
    component: AfterSale,
  },
  {
    key: 'settings',
    icon: <Kicon type="kicon-settings-fill" />,
    label: '设置',
    items: [
      {
        key: 'authority',
        functionCode: 'authorization_config_view',
        path: '/cem/settings/authority',
        label: '权限管理',
        component: BasicManagement,
      },
      {
        key: 'rules',
        functionCode: 'deduction_config_view',
        path: '/cem/settings/rules',
        label: '规则设置',
        component: Rules,
      },
      {
        key: 'supervision',
        functionCode: 'supervision_config_view',
        path: '/cem/settings/supervision',
        label: '督导设置',
        component: Supervision,
      },
    ],
  },
  {
    key: 'forbidden',
    path: '/forbidden/:code?',
    label: 'forbbiden',
    component: Forbidden,
    hidden: true,
    auther: true,
    frameless: true,
  },
];

export function getRoutes(isCEM: boolean) {
  return isCEM ? cemRoutes : kensaRoutes;
}
