import { metricsInterceptor } from 'lito.config';
import withAuth from 'utils/withAuth';
import HeaderAuth from 'utils/HeaderAuth';
import AxiosClient from 'utils/AxiosClient';

import responseInterceptor from './responseInterceptor';

const AUTH_HEADER_NAME = process.env.REACT_APP_AUTH_HEADER_NAME as string;
const headerAuth = new HeaderAuth(AUTH_HEADER_NAME, 'Bearer');
const AuthClient = withAuth(headerAuth)(AxiosClient);
export const inspectorClient = new AuthClient(
  { baseURL: process.env.REACT_APP_INSPECTOR_ENDPOINT },
  metricsInterceptor,
  { response: responseInterceptor },
);
export const inspectorClientExport = new AuthClient(
  { baseURL: process.env.REACT_APP_INSPECTOR_EXPORT_ENDPOINT },
  metricsInterceptor,
  { response: responseInterceptor },
);
export const dialogClient = new AuthClient(
  { baseURL: process.env.REACT_APP_DIALOG_ENDPOINT },
  metricsInterceptor,
  { response: responseInterceptor },
);
export function authorize(token: string, storeId: string) {
  inspectorClient.authorize(token, storeId);
  inspectorClientExport.authorize(token, storeId);
  dialogClient.authorize(token, storeId);
}
export default { inspectorClient, inspectorClientExport, dialogClient };
