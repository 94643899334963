import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';
import 'antd/dist/antd.less';
import locale from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import { client } from 'apollo-client.config';
import AuthContainer from 'storeContainers/AuthContainer';
import RoutingContainer from 'storeContainers/RoutingContainer';
import RouteMetaContainer from 'storeContainers/RouteMetaContainer';
import Loading from 'components/Loading';
import history from 'utils/history';
import { getRoutes } from 'views/Main/routes';
import CEMContainer, { useCEM } from 'storeContainers/CEMContainer';

moment.locale('zh-cn');

const Main = loadable(() => import('views/Main'), { fallback: <Loading /> });

function RouteMetaConfig(props: { children?: React.ReactNode }) {
  const { children } = props;

  const isCEM = useCEM();

  return (
    <RouteMetaContainer.Provider initialState={{ routes: getRoutes(isCEM) }}>
      {children}
    </RouteMetaContainer.Provider>
  );
}

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <ConfigProvider locale={locale}>
        <AuthContainer.Provider>
          <Router history={history}>
            <RoutingContainer.Provider>
              <CEMContainer.Provider>
                <RouteMetaConfig>
                  <Switch>
                    <Route path="/" component={Main} />
                  </Switch>
                </RouteMetaConfig>
              </CEMContainer.Provider>
            </RoutingContainer.Provider>
          </Router>
        </AuthContainer.Provider>
      </ConfigProvider>
    </ApolloProvider>
  );
};

export default App;
