import React, { useCallback, FunctionComponent } from 'react';
import { Spin, Icon } from 'antd';
import noop from 'utils/noop';
import AppLink from 'components/AppLink';
import styles from './styles.module.scss';

interface LoadingProps {
  errorText?: React.ReactNode;
  timedOutText?: React.ReactNode;
  error?: object;
  timedOut?: boolean;
  pastDelay?: boolean;
  retry?: Function;
}
const Loading: FunctionComponent<LoadingProps> = (props) => {
  const {
    errorText = '加载过程中出现错误，请稍后再试',
    timedOutText = '加载超时，请稍后再试',
    error,
    timedOut,
    pastDelay,
    retry = noop,
  } = props;

  const handleRetry = useCallback(() => {
    if (timedOut) {
      retry();
    }

    if (error) {
      window.location.reload();
    }
  }, [timedOut, error, retry]);

  if (error || timedOut) {
    return (
      <div className={styles.message}>
        <div>{error ? errorText : timedOutText}</div>
        <div>
          <AppLink onClick={retry && handleRetry}>立即重试</AppLink>
        </div>
      </div>
    );
  }

  if (pastDelay) {
    const indicator = <Icon className={styles.indicator} type="loading" />;

    return <Spin indicator={indicator} />;
  }

  return null;
};
export default Loading;
