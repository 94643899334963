import { useLocation } from 'react-router-dom';
import { createContainer } from 'unstated-next';

const CEMContainer = createContainer(() => {
  const { pathname } = useLocation();

  return /^\/cem(\/.*)?/.test(pathname);
});

export function useCEM() {
  return CEMContainer.useContainer();
}

export default CEMContainer;
