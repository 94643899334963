import history from 'utils/history';

import ApiError from './ApiError';

const respHandler = (resp: any) => resp;
const errostatusHandler = (error: any) => {
  if (error?.response) {
    const {
      response: { status },
    } = error;
    switch (status) {
      case 401:
        history.push('/forbidden/401');
        break;
      case 403:
        history.push('/forbidden/403');
        break;
      default:
        break;
    }
    return Promise.reject(new ApiError(error.message, status));
  }
  return Promise.reject(new ApiError('网络异常，请稍后再试'));
};
export default [respHandler, errostatusHandler];
