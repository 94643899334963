/* eslint-disable camelcase */
import { ApolloLink, Observable } from '@apollo/client';
import { AxiosAdapter, createDeviceid, createSessionid, Lito, Timer } from '@leyan/lito';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { decode } from 'jsonwebtoken';
import { Interceptor } from 'utils/AxiosClient';
import logger from 'utils/logger';
import { Itoken, TPayload, isILittleBooktoken } from './storeContainers/helpers';

function getStorageId(storage: Storage, key: string, fallback: () => string) {
  let id = storage.getItem(key);

  if (!id) {
    id = fallback();

    storage.setItem(key, id);
  }

  return id;
}

const LOGGER = logger.extend('lito.config');

function BrowserIntegration(lito: Lito) {
  const instance = lito as Lito<{
    app_up: {
      screen: `${number}x${number}`;
      window: `${number}x${number}`;
      referer: string;
    };
  }>;

  return () => {
    instance.capture({
      name: 'app_up',
      labels: {
        screen: `${window.screen.width}x${window.screen.height}`,
        window: `${window.innerWidth}x${window.innerHeight}`,
        referer: window.location.href,
      },
    });
  };
}

function AxiosIntegration(lito: Lito, longRequestTime: number = 2000): Interceptor {
  const instance = lito as Lito<{
    request_success: {
      url: string;
      method: Uppercase<Method>;
      status: number;
      options?: string;
    };
    request_error: {
      url: string;
      method: Uppercase<Method>;
      status?: number;
      options?: string;
      message?: string;
    };
  }>;

  const REQUEST_START_TIME_MAP: WeakMap<object, Timer> = new WeakMap();

  function capture(config: AxiosRequestConfig, response?: AxiosResponse, message?: string) {
    const duration = REQUEST_START_TIME_MAP.get(config)?.stop().duration() ?? 0;
    const url = `${config.baseURL || ''}/${config.url}`;
    const method = config.method!.toUpperCase() as Uppercase<Method>;
    const status = response?.status;
    const options =
      longRequestTime > 0 && duration > longRequestTime
        ? JSON.stringify({
            ...(config.params && {
              params: config.params,
            }),
            ...(config.data && {
              data: config.data,
            }),
          })
        : undefined;

    if (message) {
      instance.capture({
        name: 'request_error',
        value: duration,
        labels: {
          url,
          method,
          status,
          options,
          message,
        },
      });
    } else {
      instance.capture({
        name: 'request_success',
        value: duration,
        labels: {
          url,
          method,
          status: status!,
          options,
        },
      });
    }
  }

  return {
    request: [
      (config) => {
        REQUEST_START_TIME_MAP.set(config, new Timer().start());

        return config;
      },
    ],
    response: [
      (response) => {
        capture(response.config, response);

        return response;
      },
      (error) => {
        if (axios.isAxiosError(error)) {
          capture(
            error.config!,
            error.response,
            error.response?.data && typeof (error.response.data as any).message === 'string'
              ? (error.response.data as any).message
              : error.message,
          );
        }

        return Promise.reject(error);
      },
    ],
  };
}

function ApolloClientIntegration(lito: Lito, longOperationTime: number = 2000): ApolloLink {
  const instance = lito as Lito<{
    graphql_success: {
      operation: string;
      variables?: string;
    };
    graphql_error: {
      operation: string;
      variables?: string;
      message?: string;
    };
  }>;

  return new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      const timer = new Timer().start();

      function capture(error?: Error) {
        const duration = timer.stop().duration();
        const variables =
          longOperationTime > 0 && duration > longOperationTime
            ? JSON.stringify(operation.variables)
            : undefined;

        if (error) {
          instance.capture({
            name: 'graphql_error',
            value: duration,
            labels: {
              operation: operation.operationName,
              variables,
              message: error.message,
            },
          });
        } else {
          instance.capture({
            name: 'graphql_success',
            value: duration,
            labels: {
              operation: operation.operationName,
              variables,
            },
          });
        }
      }

      const subscription = forward(operation).subscribe({
        next(result) {
          const { errors } = result;

          if (errors && errors.length > 0) {
            capture(
              new Error(
                `Graphql "${operation.operationName}" error: ${errors
                  .map((error) => error.message)
                  .join('\r\n')}`,
              ),
            );
          } else {
            capture();
          }

          observer.next(result);
        },
        error(error) {
          capture(error);

          observer.error(error);
        },
        complete() {
          observer.complete();
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  });
}

function UserIntegration(lito: Lito) {
  const instance = lito as Lito<{ user_up: {} }>;
  const timer = new Timer().start();

  return (token: string, storeIdFromUrl: string) => {
    const duration = timer.stop().duration();
    const payload = decode(token, { json: true }) as TPayload | Itoken | null;

    if (payload) {
      let nick: string;
      let leyanUser: string | undefined;
      let storeId: string;
      if (isILittleBooktoken(payload)) {
        nick = payload.nick;
        leyanUser = payload.leyan_user?.nickname || '';
        storeId = String(payload.store_id);
      } else {
        nick = payload.agent?.nick || payload.agent.name;
        leyanUser = payload.type === 2 ? nick : undefined;
        storeId = storeIdFromUrl || '';
      }

      instance
        .setLabels((labels) => {
          return {
            ...labels,
            user_nick: nick,
            store_id: storeId,
            leyan_user: leyanUser,
          };
        })
        .capture({
          name: 'user_up',
          value: duration,
        });
    }
  };
}

const lito = new Lito();

lito.init({
  endpoint: process.env.REACT_APP_LITO_API_ENDPOINT,
  appname: process.env.REACT_APP_LITO_APPNAME,
  appkey: process.env.REACT_APP_LITO_APPKEY,
  deviceid: getStorageId(localStorage, 'deviceid', () => createDeviceid()),
  sessionid: getStorageId(sessionStorage, 'sessionid', () => createSessionid()),
  version: process.env.REACT_APP_VERSION!,
  adapter: AxiosAdapter(
    axios.create({
      headers: {
        'Content-Type': 'text/plain',
      },
      transitional: {
        clarifyTimeoutError: true,
      },
    }),
  ),
  onFlush(events) {
    LOGGER.debug('上报事件 x%s', events.length);
  },
  onDiscard(events) {
    LOGGER.warn('丢弃事件 x%s', events.length);
  },
  onError(error, events) {
    LOGGER.error('上报事件 x%s 错误:', events.length, error);
  },
});

export const setupApp = BrowserIntegration(lito);

export const metricsInterceptor = AxiosIntegration(lito);

export const metricsLink = ApolloClientIntegration(lito);

export const setupUser = UserIntegration(lito);

export default lito;
