export type Itoken = {
  type: 1 | 2; // 2 是员工
  agent: {
    id: string;
    name: string;
    nick?: string;
    role?: 'admin' | 'user';
    // eslint-disable-next-line camelcase
    org_id?: string;
  };
  /* eslint-enable camelcase */
  iss?: string;
  iat?: number;
  exp?: number;
};

export function isILittleBooktoken(pet: Itoken | TPayload): pet is TPayload {
  return (pet as TPayload).store_id !== undefined;
}

export type TPayload = {
  nick: string;
  /* eslint-disable camelcase */
  leyan_user?: {
    username: string;
    nickname: string;
  };
  store_id: number | string;
  shop_id: number | string;
  store_name: string;
  seller_nick: string;
  avatar_url: string | null;
  hasura: {
    'x-hasura-seller-id': string;
  };
  /* eslint-enable camelcase */
  guide?: boolean;
  iss?: string;
  iat?: number;
  exp?: number;
  longterm?: boolean;
  [key: string]: any;
};
