import { useMemo } from 'react';
import { createContainer } from 'unstated-next';
import pathToRegexp from 'path-to-regexp';
import { useLocation } from 'react-router-dom';
import { RouteData } from 'components/MenuUseRouter';

const matchRoute = (route: RouteData, pathname: string): RouteData | undefined => {
  if (!route.path) {
    return route.items ? route.items.find((item) => matchRoute(item, pathname)) : undefined;
  }
  const regexp = pathToRegexp(route.path);
  return regexp.test(pathname) ? route : undefined;
};
export const matchPath = (pathname: string, routes: RouteData[]) => {
  let matched: ReturnType<typeof matchRoute>;
  for (const item of routes) {
    matched = matchRoute(item, pathname);
    if (matched) {
      break;
    }
  }
  return matched;
};
const RouteMetaContainer = createContainer<{ currentRoute?: RouteData }, { routes: RouteData[] }>(
  (initialState) => {
    const routes: RouteData[] = useMemo(() => initialState?.routes || [], [initialState]);
    const location = useLocation();
    const currentRoute = useMemo(() => {
      const { pathname } = location;
      return matchPath(pathname, routes);
    }, [location, routes]);
    return { currentRoute };
  },
);
export const useRouteMeta = () => {
  const routeMeta = RouteMetaContainer.useContainer();
  return routeMeta;
};
export default RouteMetaContainer;
