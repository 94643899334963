import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { setupApp } from 'lito.config';
import history from 'utils/history';
import './index.css';
import App from './views/App/App';

/* 允许子域名跨域 */
document.domain =
  process.env.NODE_ENV === 'development'
    ? window.location.hostname
    : process.env.REACT_APP_DOCUMENT_DOMAIN || window.location.hostname;

setupApp();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  enabled: ['production', 'staging'].includes(process.env.REACT_APP_ENV!),
  tracesSampleRate: process.env.REACT_APP_ENV === 'staging' ? 1 : 0.1,
});

ReactDOM.render(<App />, document.getElementById('root'));
