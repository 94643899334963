import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
  Operation,
} from '@apollo/client';
import { Timer } from '@leyan/lito';

import logger from 'utils/logger';
import { metricsLink } from 'lito.config';
import { getToken } from 'storeContainers/AuthContainer';

const LOGGER = logger.extend('apollo-client.config');

function AuthLink(token?: string | null | ((operation: Operation) => string | null | undefined)) {
  return new ApolloLink((operation, forward) => {
    const current = typeof token === 'function' ? token(operation) : token;

    if (current) {
      operation.setContext(({ headers = {} }) => {
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${current}`,
          },
        };
      });
    }

    return forward(operation);
  });
}

function LogLink(log: (operation: Operation, duration: number, error?: Error) => void) {
  return new ApolloLink((operation, forward) => {
    return new Observable((observer) => {
      const timer = new Timer().start();

      function doLog(error?: Error) {
        const duration = timer.stop().duration();

        log(operation, duration, error);
      }

      const subscription = forward(operation).subscribe({
        next(value) {
          const { errors } = value;

          doLog(
            errors && errors.length > 0
              ? new Error(
                  `Graphql "${operation.operationName} error: ${errors
                    .map((error) => error.message)
                    .join('\r\n')}"`,
                )
              : undefined,
          );

          observer.next(value);
        },
        error(error) {
          doLog(error);

          observer.error(error);
        },
        complete() {
          observer.complete();
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  });
}

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_APG_API_ENDPOINT}/v1/graphql`,
});

const authLink = AuthLink(() => getToken());

const logLink = LogLink((operation, duration, error) => {
  if (error) {
    LOGGER.error('请求 "%s" 失败:', operation.operationName, error);
  }

  LOGGER.debug('请求: "%s" +%sms', operation.operationName, duration);
});

// eslint-disable-next-line import/prefer-default-export
export const client = new ApolloClient({
  link: from([metricsLink, logLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      items: {
        keyFields: ['num_iid'],
      },
      skus: {
        keyFields: ['sku_id'],
      },
      tb_categories: {
        keyFields: ['cid'],
      },
      seller_categories: {
        keyFields: ['cid'],
      },
    },
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  },
});
