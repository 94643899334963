import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';

export type RequestConfig = AxiosRequestConfig;

interface RequestFulfilledInterceptor {
  (value: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig>;
}

interface ResponseFulfilledInterceptor {
  (value: AxiosResponse<any>): AxiosResponse<any> | Promise<AxiosResponse<any>>;
}

interface RejectedInterceptor {
  (error: any): any;
}

export interface Interceptor {
  request?: [RequestFulfilledInterceptor?, RejectedInterceptor?];
  response?: [ResponseFulfilledInterceptor?, RejectedInterceptor?];
}

export function createAxiosInstance(config: AxiosRequestConfig, ...interceptors: Interceptor[]) {
  const instance = axios.create(config);

  for (const interceptor of interceptors) {
    if (interceptor) {
      const { request, response } = interceptor;

      if (request) {
        instance.interceptors.request.use(...request);
      }

      if (response) {
        instance.interceptors.response.use(...response);
      }
    }
  }

  return instance;
}

class AxiosClient {
  _config: AxiosRequestConfig;

  _interceptors?: Interceptor[];

  _instance: AxiosInstance | null;

  constructor(config: AxiosRequestConfig, ...interceptors: Interceptor[]) {
    this._config = config;
    this._interceptors = interceptors;
    this._instance = null;
  }

  buildInstance() {
    const instance = createAxiosInstance(this._config, ...(this._interceptors ?? []));

    this._instance = instance;

    return this;
  }

  config(): AxiosRequestConfig;

  config(config: AxiosRequestConfig): this;

  config(config?: AxiosRequestConfig): AxiosRequestConfig | this {
    if (config === undefined) {
      return this._config;
    }

    this._config = config;

    if (this._instance) {
      this.buildInstance();
    }

    return this;
  }

  interceptors(): Interceptor[] | undefined;

  interceptors(...interceptors: Interceptor[]): this;

  interceptors(...interceptors: Interceptor[]): Interceptor[] | undefined | this {
    if (arguments.length === 0) {
      return this._interceptors;
    }

    this._interceptors = interceptors;

    if (this._instance) {
      this.buildInstance();
    }

    return this;
  }

  patchConfig(config: AxiosRequestConfig) {
    return this.config({
      ...this._config,
      ...config,
    });
  }

  patchInterceptors(interceptors: Interceptor) {
    return this.interceptors({
      ...this._interceptors,
      ...interceptors,
    });
  }

  getInstance() {
    if (this._instance === null) {
      this.buildInstance();
    }

    return this._instance as AxiosInstance;
  }

  request<T>(config: AxiosRequestConfig) {
    return this.getInstance().request<T>(config);
  }
}

export default AxiosClient;
