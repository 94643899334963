const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID as string;

export type Page = { title?: string; location?: string; path?: string };
export type Config = {
  [x: string]: string | number | boolean;
};
export type DefineMap = {
  [x: string]: string;
};
export type EventParams = {
  [x: string]: any;
};
function createA(id: string) {
  let _user: string;
  let _customMap: DefineMap;
  let _config: Config;

  function view(page: Page) {
    gtag('config', id, {
      ..._config,
      user_id: _user,
      custom_map: _customMap,
      page_title: page.title,
      page_location: page.location,
      page_path: page.path,
    });
  }
  function config(config: Config) {
    _config = config;

    gtag('config', id, config);
  }

  function define(map: DefineMap) {
    _customMap = map;

    gtag('config', id, {
      custom_map: map,
    });
  }

  function user(user: string) {
    _user = user;

    gtag('config', id, {
      user_id: user,
    });
  }

  function event(name: string, params: EventParams) {
    gtag('event', name, params);
  }

  return {
    view,
    config,
    define,
    user,
    event,
  };
}

const a = createA(GA_MEASUREMENT_ID);

a.define({
  dimension1: 'sid',
  dimension2: 'roles',
  dimension3: 'parameters',
});
export default a;
