import { createFromIconfontCN } from '@ant-design/icons';

export type KIconType =
  | 'kicon-chat-fill'
  | 'kicon-truck-fill'
  | 'kicon-shopping-bag-fill'
  | 'kicon-customer-service-fill'
  | 'kicon-settings-fill';

const Kicon = createFromIconfontCN<KIconType>({
  scriptUrl: '//at.alicdn.com/t/font_3361518_17ocdc3lgri.js',
});

export default Kicon;
